import { useAuthProviderV2, useRoutesProvider } from "../../providers/provider-hooks.jsx";
import useIsDesktopViewPort from "../../hooks/use-is-desktop-viewport.js";
import { Link } from "react-router-dom";
import SideLink from "./side-link.jsx";
import PropTypes from "prop-types";
import { cn } from "../../utils.js";
import { Button } from "../schadcn-ui/button.jsx";

export default function SideMenu({ navVisible, closeNav }) {
    const { isDesktopViewPort } = useIsDesktopViewPort();
    const { PROFILE_LINKS, ROLES } = useRoutesProvider();
    const { userInfo } = useAuthProviderV2();

    return (
        <div className={"h-full"}>
            <div className={cn(!isDesktopViewPort ? "" : "pr-2", "flex h-full flex-col space-y-4")}>
                <div className="flex h-full flex-col space-y-2 overflow-y-scroll pr-4 text-small">
                    {PROFILE_LINKS.map((LINK, idx) => {
                        if (LINK.ALLOWED_ROLES.includes(userInfo?.role))
                            return (
                                <SideLink
                                    key={idx}
                                    path={LINK.PATH}
                                    idx={idx}
                                    closeNav={closeNav}
                                    displayName={
                                        LINK.TEXT === "Raport Conturi Elevi" && userInfo?.role === ROLES.PROFESOR
                                            ? "Elevii mei"
                                            : LINK.TEXT
                                    }
                                    navVisible={navVisible}
                                />
                            );
                    })}
                </div>
                {userInfo?.role === ROLES.PROFESOR && (
                    <Button onClick={()=>window.open('https://forms.gle/Fd6evcrf2yzcpP9k7', '_blank')} className="h-auto">
                        <span className="text-wrap">Formular Echipamente IT in scoli</span>
                    </Button>
                )}
                <Link
                    to={"/"}
                    className="flex min-w-[140px] items-center justify-center md:min-w-[160px] lg:min-w-[190px]">
                    <img src="/logo-ecotic.png" alt="ECOTIC logo" className="h-full max-h-[75px]" />
                </Link>
            </div>
        </div>
    );
}
SideMenu.propTypes = {
    navVisible: PropTypes.bool.isRequired,
    closeNav: PropTypes.func.isRequired,
};
